import React from "react";
import classNames from "classnames";
import { MailchimpForm } from "@molecules";
import { Container, Text } from "@atoms";
import useDefaultsData from "../../craft/staticQueries/DefaultsQuery";

const NewsletterCta = ({
  bgColor,
  heading,
  descriptor,
  form,
  paddingBottom,
  className: _className,
}) => {
  const { form: defaults } = useDefaultsData();
  return (
    <section
      className={classNames(
        "pb-16",
        {
          "bg-midnight": bgColor === "midnight",
          "md:pb-32": paddingBottom,
        },
        _className
      )}
    >
      <Container variant="sm">
        <div className="flex flex-wrap items-center gap-12 md:flex-nowrap md:gap-24">
          <div className="w-full md:w-3/5">
            <div className="flex flex-col space-y-4">
              <Text
                variant="h2"
                className={classNames({
                  "text-mint": bgColor === "midnight",
                })}
              >
                {heading || defaults.heading}
              </Text>
              <Text
                variant="xl"
                className={classNames({
                  "text-white": bgColor === "midnight",
                })}
              >
                {descriptor || defaults.descriptor}
              </Text>
            </div>
          </div>
          <div className="w-full md:w-2/5">
            <MailchimpForm {...form} />
          </div>
        </div>
      </Container>
    </section>
  );
};

NewsletterCta.defaultProps = {
  bgColor: "midnight",
  paddingBottom: false,
};

export default NewsletterCta;
